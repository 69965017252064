import {
  ILeaveBalance,
  POSTEmployeeBody,
  POSTInviteEmployeeBody,
  POSTSearchUserBody,
  PUTEmployeeBody,
  PUTEmployeeWorkHoursBody
} from './index'
import { HttpMethod, IPaginatedRequest } from '@cbgms/api'

export const loadSingleEmployee = (uuid: string) => ({
  method: HttpMethod.GET,
  url: `/modules/employees/${uuid}`
})

export const loadEmployeesList = (params: IPaginatedRequest) => ({
  method: 'GET',
  url: '/modules/employees',
  params
})

export const createNewEmployee = (data: POSTEmployeeBody) => ({
  method: 'POST',
  url: '/modules/employees',
  data
})

export const updateExistingEmployee = (uuid: string, data: PUTEmployeeBody) => ({
  method: 'PUT',
  url: `/modules/employees/${uuid}`,
  data
})

export const getLeaveBalanceRequest = (employeeUUID: string, year: number) => ({
  method: 'GET',
  url: `/modules/leave/${employeeUUID}/${year}`
})

export const getLeaveYearListRequest = (employeeUUID: string) => ({
  method: 'GET',
  url: `/modules/employees/${employeeUUID}/leave`
})

// localhost:9801/modules/employees/7a587e21-f0fa-6f71-313c-f6e8550b0081/leave

export const getConsolidatedLeaveBalanceRequest = (employeeUUID: string, year: number) => ({
  method: 'GET',
  url: `/modules/consolidated-leave/${employeeUUID}/${year}`
})

export const addLeaveBalanceRequest = (employeeUUID: string, data: ILeaveBalance) => ({
  method: 'POST',
  url: `/modules/leave/${employeeUUID}`,
  data
})

export const updateLeaveBalanceRequest = (uuid: string, data: ILeaveBalance) => ({
  method: 'PUT',
  url: `/modules/leave/${uuid}`,
  data
})

export const employeeAcceptTermsAndConditions = () => ({
  method: 'PUT',
  url: `/modules/employees/current/accept-terms-conditions`
})

export const inviteNewEmployee = (uuid: string, data: POSTInviteEmployeeBody) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/invite`,
  data
})

export const loadExistingEmployeeStatus = (uuid: string) => ({
  method: 'GET',
  url: `/modules/employees/${uuid}/user`
})

export const passwordReset = (uuid: string, shopID?: string) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/reset-user-password`,
  data: { ShopID: shopID }
})

export const setTemporaryPassword = (uuid: string, shopID: string, temporaryPassword: string) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/set-temporary-password`,
  data: { ShopID: shopID, TemporaryPassword: temporaryPassword }
})

export const disableEmployeeAccount = (uuid: string) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/disable-user`
})

export const enableEmployeeAccount = (uuid: string) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/enable-user`
})

export const deleteEmployeeUser = (uuid: string) => ({
  method: 'POST',
  url: `/modules/employees/${uuid}/delete-user`
})

export const updateExistingEmployeeWorkHours = (uuid: string, data: PUTEmployeeWorkHoursBody) => ({
  method: 'PUT',
  url: `/modules/employees/${uuid}/work-hours`,
  data
})

export const getEmployeeWorkHours = (uuid: string) => ({
  method: 'GET',
  url: `/modules/employees/${uuid}/work-hours`
})

export const deleteEmployeeWorkHours = (uuid: string) => ({
  method: 'DELETE',
  url: `/modules/employees/${uuid}/work-hours`
})

export const searchUserRequest = (data: POSTSearchUserBody) => ({
  method: 'POST',
  url: `/modules/employees/search-user`,
  data
})
