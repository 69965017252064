export const UPDATE_SHOP = 'UPDATE_SHOP'
export const UPDATE_SHOP_SUCCESS = 'UPDATE_SHOP_SUCCESS'
export const UPDATE_SHOP_FAILED = 'UPDATE_SHOP_FAILED'

export const GET_SHOP = 'GET_SHOP'
export const GET_SHOP_SUCCESS = 'GET_SHOP_SUCCESS'
export const GET_SHOP_FAILED = 'GET_SHOP_FAILED'

export const GET_COMPANY_SHOPS = 'GET_COMPANY_SHOPS'
export const GET_COMPANY_SHOPS_SUCCESS = 'GET_COMPANY_SHOPS_SUCCESS'
export const GET_COMPANY_SHOPS_FAILED = 'GET_COMPANY_SHOPS_FAILED'

export const GET_CURRENT_SHOP = 'GET_CURRENT_SHOP'
export const GET_CURRENT_SHOP_SUCCESS = 'GET_CURRENT_SHOP_SUCCESS'
export const GET_CURRENT_SHOP_FAILED = 'GET_CURRENT_SHOP_FAILED'

export const GET_SHOP_ADDITIONAL_SETTINGS = 'GET_SHOP_ADDITIONAL_SETTINGS'
export const GET_SHOP_ADDITIONAL_SETTINGS_SUCCESS = 'GET_SHOP_ADDITIONAL_SETTINGS_SUCCESS'
export const GET_SHOP_ADDITIONAL_SETTINGS_FAILED = 'GET_SHOP_ADDITIONAL_SETTINGS_FAILED'

export const UPDATE_SHOP_ADDITIONAL_SETTINGS = 'UPDATE_SHOP_ADDITIONAL_SETTINGS'
export const UPDATE_SHOP_ADDITIONAL_SETTINGS_SUCCESS = 'UPDATE_SHOP_ADDITIONAL_SETTINGS_SUCCESS'
export const UPDATE_SHOP_ADDITIONAL_SETTINGS_FAILED = 'UPDATE_SHOP_ADDITIONAL_SETTINGS_FAILED'
