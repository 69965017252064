import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalHistory } from 'contexts/LocalHistoryContext'

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()
  const blocklistRoutes = ['/product-details-modal']
  const { history } = useLocalHistory()

  useEffect(() => {
    for (let i = 0; i < blocklistRoutes.length; i++) {
      if (pathname.includes(blocklistRoutes[i]) || (history.length && history[0]?.pathname.includes(blocklistRoutes[i]))) {
        break
      } else {
        i === blocklistRoutes.length - 1 && scrollTo({ top: 0, behavior: 'auto' })
      }
    }
  }, [pathname])

  return null
}

export default ScrollToTop
