import { Reducer } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from '../types'
import { IWorkorderAction } from '@cbgms/api'

export type AmountLimitReducerState = Readonly<IWorkorderAction | null>

type AmountLimitReducerBehavior<S> = Record<typeof types.SET_AMOUNT_LIMIT, Reducer<S, any>>

const initialState = null

const workorderAmountLimitReducer = createReducer<AmountLimitReducerState, AmountLimitReducerBehavior<AmountLimitReducerState>>(
  initialState,
  {
    [types.SET_AMOUNT_LIMIT]: (_state, { payload }) => payload
  }
)

export default workorderAmountLimitReducer
