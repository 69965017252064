export enum ProposalSource {
  Planner = 'planner',
  FIFM = 'fifm',
  VMN_NL = 'mijngarage-nl',
  VMN_BE = 'mijngarage-be',
  VMN_FR = 'mijngarage-fr',
  Vakgarage_NL = 'vakgarage-nl',
  BudgetService_NL = 'budgetservice-nl',
  AutoFirst_NL = 'autofirst-nl',
  AutoProfijt_NL = 'autoprofijt-nl',
  Mongarage_BE = 'mongarage-be',
  Carteam_NL = 'carteam-nl',
  OneTec = 'onetec',
  Other = 'other'
}
