import { CountryCode } from '@carsys/enums/country-code'
import {
  DeliveryOption,
  ForeignWorkorderLineType,
  OrderDeliveryStatus,
  OrderStatus,
  OrderType,
  PaymentOption,
  ProductIDType,
  ROBErrorType,
  ReplacementVehicleBookingStatus,
  UserType,
  VatType,
  WorkorderActionType,
  WorkorderLineGroup,
  WorkorderLineStatus,
  WorkorderLineType,
  WorkorderStatus,
  WorkorderType
} from '../enums'
import { IVehicle } from '../vehicles'
import { IAccount } from '../accounts'
import { IArrayResponse, IPaginatedResponse, IResponse } from '../../response'
import { MediaItemList } from '../media'
import { CatalogGroupNumbers, IFilter, IProduct } from '../products'
import { IWholesalerGMS } from '../wholesaler'
import { APIResponse, DELETEResponse, GETResponse } from '../../helpers'
import { IAppointment } from '../workorder-appointment'
import { IExternalPart } from '@cbgms/api/modules/products'
import { SupplierOrderLine } from './supplier-order'
import { WorkorderInvoiceType } from '../enums/workorder-invoice-type'

export * from './supplier-order'
export * from './order-form'

type CustomWorkorderAction = 'mot' | 'tyre-change' | 'road-assistance'

export enum LinkVehicleToWorkorderRejectionType {
  RelatedWorkOrders = 'related_workorders'
}

export enum LinkVehicleToWorkorderWarningType {
  LinkAccount = 'link_account'
}

export enum LinkVehicleToWorkorderResolvingActionType {
  IgnoreRelatedWorkorders = 'ignore_related_workorders',
  LinkWorkorderAccountToVehicle = 'link_workorder_account_to_vehicle',
  DoNotLinkAccount = 'do_not_link_account'
}

export interface LinkVehicleToWorkorderResolvingAction {
  // for now only ActionType, but in the future some metadata can be added
  ActionType: LinkVehicleToWorkorderResolvingActionType
}

export interface LinkVehicleToWorkorderRejection {
  Type: LinkVehicleToWorkorderRejectionType.RelatedWorkOrders
  Data: IWorkorderResponse[]
  ResolvingActions: LinkVehicleToWorkorderResolvingAction[]
}

export interface LinkVehicleToWorkorderWarning {
  Type: LinkVehicleToWorkorderWarningType.LinkAccount
  Data: {
    WorkorderAccount: IAccount
    VehicleAccount: IAccount
  }
  ResolvingActions: LinkVehicleToWorkorderResolvingAction[]
}

export interface IWorkorderSundries {
  UUID: string
  Used: boolean
}

export interface IWorkorder {
  UUID: string
  ID: string
  ShopID: string
  AccountUUID: string
  InvoiceAccountUUID: string
  VehicleUUID: string
  ProposalUUID: string
  Notes: string
  InvoiceRemark: string
  InternalRemark: string
  CreatedAt: string
  UpdatedAt: string
  Status: WorkorderStatus
  PreviousInvoiceRemark: string
  PreviousInternalRemark: string
  UseLeaseFlow: boolean
  CreatedByUUID: string
  CreatedByName: string
  WorkorderType: WorkorderType
  ROBError?: IROBError
  DefaultInvoiceType: WorkorderInvoiceType
  DefaultDepartmentUUID: string
  DefaultExpenseOfUUID: string
  DefaultIsClaim: boolean
}

export interface IROBError {
  Message: string
  Type: ROBErrorType
  UUID: string
}

export interface IWorkorderResponse {
  Account: IAccount
  Vehicle: IVehicle
  Workorder: IWorkorder
  Appointment?: IAppointment
  InvoiceAccount?: IAccount
}

export interface IWorkorderWithRelated {
  Workorder: IWorkorder
  Account?: IAccount
  InvoiceAccount?: IAccount
  VehicleAccount?: IAccount
  VehicleCompanyAccount?: IAccount
  VehicleLeaseCompanyAccount?: IAccount
  Vehicle?: IVehicle
  Appointment?: IAppointment
}

export interface IWorkorderLine {
  CatalogGroupNumbers: CatalogGroupNumbers
  CodeCustom: string
  CostPriceCustom: number
  CreatedAt: string
  CustomActions?: CustomWorkorderAction[]
  DepartmentUUID: string
  DescriptionCustom: string
  Discount: number
  DiscountIsAmount: boolean
  Duration?: number
  ErrorCode: string
  ExpenseOfUUID: string
  ExternalPart?: IExternalPart
  FixedPriceDifference?: number
  ForeignID?: string
  ForeignType?: ForeignWorkorderLineType
  GenericArticleDescription: string
  GenericArticleID: string
  HasOrderForm: boolean
  // IgnoredInTotals is used as a trick to see if the line is user editable or relevant for the user. Mostly used in package deals.
  // Mostly used for package deals that are sold for a fixed price: the main line shows the fixed price and the child lines should not
  // show pricing information (i.e.: have IgnoreInTotals set to true).
  IgnoredInTotals: boolean
  InvoiceType: WorkorderInvoiceType
  IsClaim: boolean
  IsPlaceholder: boolean
  IsService: boolean
  LineCode: string
  LineCostPrice: number
  LineDescription: string
  LineDiscountAmount: number
  LineDiscountPercentage: number
  LineImages?: {
    LineImages: string[]
  }
  LinePriceVatExcl: number
  LinePriceVatIncl: number
  LinePriceWithoutDiscountVatExcl: number
  LinePriceWithoutDiscountVatIncl: number
  LineQuantityDivisible: boolean
  LineStatus: WorkorderLineStatus
  LineType: WorkorderLineType
  LineTypeUUID: string
  LineUnitDiscountAmount: number
  LineUnitDiscountAmountVatIncl: number
  LineUnitPrice: number
  LineUnitPriceIncludingDiscount: number
  LineUnitPriceVatIncl: number
  OrderAccountUUID: string
  OrderDeliveredQuantity: number
  OrderDeliveryStatus: OrderDeliveryStatus
  OrderedQuantity: number
  OrderFormDescription?: string
  OrderStatus?: OrderStatus
  OrderType: OrderType
  ParentLineUUID: string
  PriceType?: LinePriceType
  Quantity: number
  ReplacementVehicleBookingStatus: ReplacementVehicleBookingStatus
  SalesPriceCustom: number
  SortOrder: number
  StatusChanges?: IWorkorderLineStatusChange[]
  SupplierAccountUUID?: string
  SupplierProductGroupID: string
  SupplierProductID: string
  SupplierProductIDType?: ProductIDType
  TechnicalDataDuration?: number
  TyreMutationUUID: string
  UpdatedAt: string
  UseCodeCustom: boolean
  UseDescriptionCustom: boolean
  UseSalesPriceCustom: boolean
  VatType: VatType
  UUID: string
  WarehouseStock: number
  WorkorderUUID: string
  HasSummedParent: boolean
}

export interface PublicWorkorderLine {
  UUID: string
  ParentLineUUID: string
  Quantity: number
  SortOrder: number
  LineImages?: {
    LineImages: string[]
  }
  LineStatus: WorkorderLineStatus
  LineType: WorkorderLineType
  LineDescription: string
  LinePriceVatIncl: number
  LineCode: string
  IsPlaceholder: boolean
  ShowPrice: boolean
  ShowQuantity: boolean
  isChildLine?: boolean
  childLines?: PublicWorkorderLine[]
}

export interface IWorkorderLineStatusChange {
  ChangedAt: string
  ChangedByName: string
  ChangedByType: UserType
  NewStatus: WorkorderLineStatus
  PreviousStatus: WorkorderLineStatus
}

export interface IVatType {
  Type: VatType
  LineGroup: WorkorderLineGroup
  Percentage: number
  BaseAmount: number
  VatAmount: number
}

export interface ITotal {
  VatExcluded: number
  VatIncluded: number
  VatAmount: number
  MarginAmount: number
  VatTypes: IVatType[]
  DiscountAmountVatExcluded: number
  DiscountPercentage: number
  CostPriceVatExcluded: number
}

export interface IStatusTotal extends ITotal {
  PartsVatExcluded: number
  LabourVatExcluded: number
  OtherVatExcluded: number
}

export interface IWorkorderTotals extends ITotal {
  // TotalIncludingRejected contains the total, including the rejected lines. One might say this should actually be what ITotal represents.
  // But since ITotal already existed before we wanted to know the total-including-rejected values, we decided to add this field, instead
  // of changing the current Total field.
  TotalIncludingRejected: ITotal

  TotalApproved: IStatusTotal
  TotalPending: IStatusTotal
  TotalRejected: IStatusTotal
  TotalQuote: IStatusTotal

  TotalWarranty: IStatusTotal
  TotalInternal: IStatusTotal
}

export const emptyTotal = {
  VatExcluded: 0,
  VatIncluded: 0,
  VatAmount: 0,
  MarginAmount: 0,
  VatTypes: [],
  DiscountAmountVatExcluded: 0,
  DiscountPercentage: 0,
  CostPriceVatExcluded: 0
}
export const emptyStatusTotal = {
  PartsVatExcluded: 0,
  LabourVatExcluded: 0,
  OtherVatExcluded: 0,
  ...emptyTotal
}

export const emptyWorkorderTotals: IWorkorderTotals = {
  ...emptyStatusTotal,
  TotalIncludingRejected: emptyTotal,
  TotalApproved: emptyStatusTotal,
  TotalPending: emptyStatusTotal,
  TotalRejected: emptyStatusTotal,
  TotalQuote: emptyStatusTotal,

  TotalWarranty: emptyStatusTotal,
  TotalInternal: emptyStatusTotal
}

export interface IWorkorderLines {
  WorkorderUUID: string
  Lines: IWorkorderLine[]
  Totals: IWorkorderTotals
}

export interface IWorkorderPortalDashboard {
  UUID: string
  CreatedAt: string
  Name: string
  CompanyName: string
  Registration: string
}

export type GETWorkorderLinesResponse = GETResponse<IWorkorderLines>

export interface IMutateWorkorderLinesResponse extends IWorkorderLines {
  UUID: string
}

export type PUTWorkorderLineResponse = APIResponse<IMutateWorkorderLinesResponse>
export type POSTWorkorderLineResponse = APIResponse<IMutateWorkorderLinesResponse>

export interface IUpdateWorkorderLineRequest {
  ParentLineUUID?: string
  VatType?: string
  Quantity?: number
  Discount?: number
  DiscountIsAmount?: boolean
  UseSalesPriceCustom?: boolean
  SalesPriceCustom?: number
  UseCostPriceCustom?: boolean
  CostPriceCustom?: number
  UseCodeCustom?: boolean
  CodeCustom?: string
  UseDescriptionCustom?: boolean
  DescriptionCustom?: string
  SupplierProductID?: string
  SupplierProductIDType?: ProductIDType
  OrderAccountUUID?: string
  SupplierAccountUUID?: string // usually same as order account uuid. but editable, and could be filled for offline purchase.
  SupplierProductGroupID?: string
  LineStatus?: string
  LineImages?: {
    LineImages: string[]
  }
  UpdatedAt?: string
  collection?: { childToParent: string; parentToChild: string }
  PriceType?: LinePriceType
  InvoiceType?: WorkorderInvoiceType
  CatalogGroupNumbers?: CatalogGroupNumbers
  ExternalPart?: Partial<IExternalPart>
}

export interface ICreateWorkorderLineRequest {
  VatType?: VatType
  Quantity: number
  Discount?: number
  DiscountIsAmount?: boolean
  UseSalesPriceCustom?: boolean
  SalesPriceCustom?: number
  UseCostPriceCustom?: boolean
  CostPriceCustom?: number
  UseCodeCustom?: boolean
  CodeCustom?: string
  UseDescriptionCustom?: boolean
  DescriptionCustom?: string
  SupplierProductID?: string
  SupplierProductIDType?: ProductIDType
  SupplierProductGroupID?: string
  LineStatus: WorkorderLineStatus
  UpdatedAt?: string

  ParentLineUUID?: string
  SortOrder?: number
  LineType: WorkorderLineType
  LineTypeUUID?: string
  SupplierAccountUUID?: string
  OrderAccountUUID?: string
  ForeignType?: ForeignWorkorderLineType
  ForeignID?: string
  GenericArticleID?: string
  GenericArticleDescription?: string
  CreatedAt?: string
  UseParentQuantity?: boolean

  PriceType?: LinePriceType

  OrderDirect?: boolean
  Reference?: string

  InvoiceType?: WorkorderInvoiceType
  DepartmentUUID?: string
  ExpenseOfUUID?: string
  IsClaim?: boolean
  CatalogGroupNumbers?: CatalogGroupNumbers

  ExternalPart?: Partial<IExternalPart>
}

export enum LinePriceType {
  Summed = 'summed',
  Fixed = 'fixed'
}

export interface WorkorderLineState extends IWorkorderLine {
  isChildLine?: boolean
  childLines?: WorkorderLineState[]
}

export interface WorkorderTotalAmount {
  incVat: number
  excVat: number
}

export interface PostMultipleLines {
  Lines: string[]
}

export type CreateWorkorderLineRequest = ICreateWorkorderLineRequest
export type CreateOfflinePurchaseLine = Omit<ICreateWorkorderLineRequest, 'LineStatus' | 'LineType'>
export interface CreateOfflinePurchaseLineRequest {
  Lines: CreateOfflinePurchaseLine[]
}
export type UpdateWorkorderLineRequest = IUpdateWorkorderLineRequest

export type GETWorkorderResponse = APIResponse<IWorkorder>
export type GETWorkorderWithRelatedResponse = APIResponse<IWorkorderWithRelated>
export type GETWorkordersResponse = IPaginatedResponse<IWorkorderResponse>
export type GetWorkorderLinesResponseWithData = GETWorkorderLinesResponse
export type GetWorkorderLineResponse = IResponse<IWorkorderLine>
export type CreateWorkorderLineResponse = IMutateWorkorderLinesResponse
export type UpdateWorkorderLineResponse = IMutateWorkorderLinesResponse
export type PlaceOrderUpdateWorkorderLineResponse = IMutateWorkorderLinesResponse
export type DeleteWorkorderLineResponse = IMutateWorkorderLinesResponse

export interface IAdvancedSearchResult {
  Account?: IAccount
  Vehicle?: IVehicle
}

export type GetAdvancedSearchResponse = IArrayResponse<IAdvancedSearchResult>

export enum SignalType {
  MOTExpiration = 'mot expiration',
  Birthday = 'birthday',
  TyreChangeToSummer = 'tyre-change-to-summer',
  TyreChangeToWinter = 'tyre-change-to-winter',
  TyreChangeHistoryFailed = 'tyre-change-history-failed',
  TyreChangeCurrentSummerTreadBelowThreshold = 'tyre-change-current-summer-tread-below-threshold',
  TyreChangeStoredSummerTreadBelowThreshold = 'tyre-change-stored-summer-tread-below-threshold',
  TyreChangeCurrentWinterTreadBelowThreshold = 'tyre-change-current-winter-tread-below-threshold',
  TyreChangeStoredWinterTreadBelowThreshold = 'tyre-change-stored-winter-tread-below-threshold'
}

export interface ISignal {
  Type: SignalType
  Date: string
}

export interface IPaymentOption {
  ID: string
  PaymentType: PaymentOption
}

export interface ITime {
  ID: string
  Time: string
  Description: string
}

export interface IDeliveryOption {
  Times: ITime[]
  Type: DeliveryOption
}

export interface ISupplierAddress {
  Address: string
  HouseNumber: string
  PostalCode: string
  Country: CountryCode
  City: string
  ID: string
  EmailAddress: string
  FaxNumber: string
  Website: string
  Name: string
}

export interface IOrderLineRequestParam {
  WorkorderLineUUID: string
  QuantityToOrder: number
}

export type GETWorkorderSignalsResponse = IArrayResponse<ISignal>

export type GETWorkorderImagesResponse = APIResponse<MediaItemList>

export interface PUTLinkVehicleToWorkorderResponse {
  Workorder: IWorkorder
  Vehicle: IVehicle
  Account: IAccount
  Rejections: LinkVehicleToWorkorderRejection[]
  Warnings: LinkVehicleToWorkorderWarning[]
}

export interface LinkVehicleToRequest {
  VehicleUUID: string
  Actions: LinkVehicleToWorkorderResolvingAction[]
}

export type PUTLinkVehicleToWorkorder = APIResponse<PUTLinkVehicleToWorkorderResponse>
export type PUTLinkVehicleToRequest = LinkVehicleToRequest
export type PUTUnlinkVehicleToWorkorder = APIResponse<{ UUID: string }>

export interface IVehicleProductHistory {
  Products: IProduct[]
  Filters: IFilter[]
}

export type GETVehicleProductHistoryReponse = APIResponse<IVehicleProductHistory>

export interface IWorkorderItemSearchResult {
  Brand: string
  Code: string
  Description: string
  Divisible: boolean
  LineType: WorkorderLineType
  LineTypeUUID: string
  LocalStock: number
  Quantity: number
  SalesPercentage: number
  SalesPrice: number
  CostPrice: number
  VatType: VatType
}

export interface IWorkorderItemSearchData {
  OrderAccounts: IWholesalerGMS[]
  Results: IWorkorderItemSearchResult[]
}

export type InvoiceTypeSuggestionReason =
  | 'active-warranty'
  | 'vehicle-in-stock'
  | 'courtesy-vehicle'
  | 'rental-vehicle'
  | 'sold-in-last-3-days'
export interface IInvoiceTypeSuggestion {
  DepartmentUUID: string
  DepartmentName: string
  InvoiceType: WorkorderInvoiceType
  Reason: InvoiceTypeSuggestionReason
}

export type POSTWorkorderResponse = APIResponse<{ UUID: string }>
export type DELETEWorkorderResponse = DELETEResponse
export type GETWorkorderItemSearch = GETResponse<IWorkorderItemSearchData>
export type GETInvoiceTypeSuggestion = GETResponse<IInvoiceTypeSuggestion>

export interface WholesalerIframeCart {
  SkippedItems: boolean
  WorkorderUUID: string
  Lines: IWorkorderLine[]
  Totals: IWorkorderTotals
  Sundries: IWorkorderSundries
}

export type GETWholesalerIframeURL = APIResponse<{ URL: string; SessionID: string; SupportsIFrame: boolean }>
export type PUTWholesalerIframeCartToWorkorder = APIResponse<WholesalerIframeCart>

export interface ISupplierOrderParams {
  OrderLines: SupplierOrderLine[]
  DeliveryAddress?: ISupplierAddress[]
  PickupAddress?: ISupplierAddress[]
  DeliveryOptions?: IDeliveryOption[]
  PaymentOptions?: IPaymentOption[]
  ExternalPlaceOrderURL: string
  DefaultCustomerOrderReference: string
  orderAccountUUID?: string
}

export type POSTSupplierOrderParamsResponse = APIResponse<ISupplierOrderParams>

export interface IOrderData {
  DeliveryOption: 'delivery' | 'pickup'
  Reference?: string
  Remarks?: string
  Time: string
  WorkorderUUID: string
  OrderAccountUUID?: string
}

export interface IParamsData {
  OrderAccountUUID: string
  Lines: IParamsLines[]
}

export interface IParamsLines {
  WorkorderLineUUID: string
  QuantityToOrder: number
}

export interface SupplierOrderResult {
  Orders: SupplierOrder[]
}

export interface SupplierOrder {
  OrderID: string
  Status: string
}

export interface PlaceSupplierOrder {
  Order: SupplierOrderResult
}

export interface PlaceSupplierOrderOutput extends IWorkorderLines, PlaceSupplierOrder {}

export type POSTSupplierOrderResponse = APIResponse<PlaceSupplierOrderOutput>

export interface IPostSupplierOrderData {
  OrderAccountUUID?: string
  SupplierAccountUUID?: string
  Lines: IOrderLineRequestParam[]
  Params: IPostSupplierOrderParams
}

export interface IPostSupplierOrderParams {
  AddressID: string
  CustomerOrderReference: string
  DeliveryID: string // the selected id withing the options, when 'preferred_delivery_date' was used, this becomes the user selected time
  DeliveryOption: DeliveryOption // Enum option selected by user
  DeliveryTime: string // user selected time for 'prefered_delivery_date'
  DeliveryDateTime: string // calculated time based on delivery option (asap)
  DeliveryDescription: string // the text-description for selected DeliveryID (usually the date/time, might contain additionlan info)
  PaymentID: string
  PaymentMethod: PaymentOption
  Remark: string
}

export interface IDiscounts {
  Discounts: IWorkorderLineDiscount[]
  DryRun: boolean
}

export interface IWorkorderLineDiscount {
  WorkorderLineUUID: string
  Value: number
  Type: string
  IncludeVAT: boolean
}

export interface IDefaultInvoiceTypeRequest {
  ApplyToAllLines: boolean
  InvoiceType: WorkorderInvoiceType
  DepartmentUUID: string
  ExpenseOfUUID: string
  IsClaim?: boolean
}

export interface ISubmitInvoiceRequest {
  InvoiceNumber: string
  InvoiceDate: string
}

export interface IEmailProposalRequest {
  EmailAddress: string
  AccountUUID: string
}

export interface IEmailProposalEmailList {
  EmailAddress: string | undefined
  AccountUUID: string | undefined
  Type: string
}

export interface ISmsProposalMobileNumberList {
  MobileNumber: string | undefined
  AccountUUID: string
  Type: string
}

export type PUTDiscountResponse = APIResponse<IWorkorderLines>

interface AmountLimit {
  Amount: number
  MobileNumber: string
}

export interface ActionValues {
  AmountLimit: AmountLimit
}

export interface IWorkorderAction {
  UUID: string
  ShopID: string
  CompanyUUID: string
  WorkorderUUID: string
  ActionType: WorkorderActionType
  ActionValues: ActionValues
  CreatedAt: string
  UpdatedAt: string
}

export interface ICreateWorkorderActionRequest {
  ActionType: WorkorderActionType
  ActionValues: ActionValues
}

export type GETWorkorderAction = GETResponse<IWorkorderAction[]>
export type CreateWorkorderActionRequest = ICreateWorkorderActionRequest
