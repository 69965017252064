import {
  AuthenticationResult,
  CognitoResponse,
  EmailCheckResponse,
  GETConsolidatedLeaveBalanceResponse,
  GETEmployeeResponse,
  GETHelpdeskParamsResponse,
  GETLeaveBalanceResponse,
  GETLeaveYearListResponse,
  GETLocalisationSettingsResponse,
  GETPublicOnboardingWholesalers,
  GETPublicOrderAccountCheckResponse,
  GETPublicShopContactInformation,
  GETPublicShopEmailCheckResponse,
  GETPublicShopPhoneNumberCheckResponse,
  GETPublicThemeByUUIDResponse,
  GETPublicVATNumberCheckResponse,
  GETPublicWholesalersByUUIDResponse,
  GETResponse,
  IArrayResponse,
  IResponse,
  POSTAddressCheck,
  POSTForgotPasswordBody,
  POSTForgotPasswordConfirmBody,
  POSTForgotPasswordConfirmResponse,
  POSTForgotPasswordResponse,
  POSTLoginBody,
  POSTLoginResponse,
  POSTOnboardingLoginBody,
  POSTOnboardingLoginResponse,
  POSTOnboardingSignUp,
  POSTPublicShopAddressCheckResponse,
  POSTRefreshTokenBody,
  POSTRefreshTokenResponse
} from '@cbgms/api'
import { Locales } from '@carsys/enums/locales'
import * as settingsRequests from '@cbgms/api/modules/settings/requests'
import * as types from './types'
import { PUTLocalisationSettingsBody } from '@cbgms/api/modules/settings'
import { AsyncAction } from '@cbgms/base/redux/async-action'
import { createAsyncApiAction } from '@cbgms/base/redux/create-async-action'
import * as publicReq from '@cbgms/api/public/onboarding'
import { ShopRegion } from 'app/settings/state/AbsenceSchedule/types'

export const handleLogin = (data: POSTLoginBody) =>
  AsyncAction<POSTLoginResponse>({
    type: [types.LOGIN, types.LOGIN_SUCCESS, types.LOGIN_FAILED],
    payload: {
      api: 'sso',
      request: {
        method: 'POST',
        url: '/modules/auth/login',
        data
      }
    }
  })

export const handlePasswordForgot = (data: POSTForgotPasswordBody) =>
  AsyncAction<POSTForgotPasswordResponse>({
    type: [types.PASSWORDFORGOT, types.PASSWORDFORGOT_SUCCESS, types.PASSWORDFORGOT_FAILED],
    payload: {
      api: 'gms', // actually send to gms, so the gms-api can determine the email scope
      request: {
        method: 'POST',
        url: '/auth/auth/forgot-password',
        data: { ...data }
      }
    }
  })

export const getShopTheme = () => ({
  type: [types.GET_SHOP_THEME, types.GET_SHOP_THEME_SUCCESS, types.GET_SHOP_THEME_FAILED],
  payload: {
    api: 'gms',
    request: {
      method: 'GET',
      url: '/modules/shops/theme'
    }
  }
})

export const handlePasswordChange = (data: POSTForgotPasswordConfirmBody) =>
  AsyncAction<POSTForgotPasswordConfirmResponse>({
    type: [types.PASSWORDCHANGE, types.PASSWORDCHANGE_SUCCESS, types.PASSWORDCHANGE_FAILED],
    payload: {
      api: 'sso',
      request: {
        method: 'POST',
        url: '/modules/auth/forgot-password-confirm',
        data
      }
    }
  })

export const requestNewAccessToken = (data: POSTRefreshTokenBody) =>
  AsyncAction<POSTRefreshTokenResponse>({
    type: [types.CREATE_ACCESS_TOKEN, types.SET_ACCESS_TOKEN, types.LOGOUT],
    payload: {
      api: 'sso',
      request: {
        method: 'POST',
        url: '/modules/auth/refresh-token',
        data
      }
    }
  })

export const handleValidateWholesaler = (data: POSTOnboardingLoginBody) =>
  AsyncAction<POSTOnboardingLoginResponse>({
    type: [types.VALIDATE_WHOLESALER, types.VALIDATE_WHOLESALER_SUCCESS, types.VALIDATE_WHOLESALER_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'POST',
        url: '/public/onboarding/validate-wholesaler',
        data
      }
    }
  })

export const getShopContactInformation = (data: POSTOnboardingLoginBody) =>
  createAsyncApiAction<GETPublicShopContactInformation>('POST_VALIDATE_CONTACT_INFORMATION', 'gms', {
    method: 'POST',
    url: '/miscellaneous/onboarding/get-contact-info-customer',
    data
  })

export const getOnboardingWholesalers = (filterAvailableForPortal: boolean) =>
  createAsyncApiAction<GETPublicOnboardingWholesalers>('GET_ONBOARDING_WHOLESALERS', 'gms', {
    method: 'GET',
    url: `/miscellaneous/onboarding/wholesalers`,
    params: { filterAvailableForPortal }
  })

export const getWholesalerByUUID = (uuid: string) =>
  AsyncAction<GETPublicWholesalersByUUIDResponse>({
    type: [types.ONBOARD_GET_WHOLESALER, types.ONBOARD_GET_WHOLESALER_SUCCESS, types.ONBOARD_GET_WHOLESALER_FAILED],
    payload: {
      api: 'gms',
      request: publicReq.publicGetWholesalerByUUID(uuid)
    }
  })

export const getHelpdeskParams = () => createAsyncApiAction<GETHelpdeskParamsResponse>('gms', publicReq.publicGetHelpdeskParams())

export const getThemeByUUID = (uuid: string) =>
  AsyncAction<GETPublicThemeByUUIDResponse>({
    type: [types.ONBOARD_GET_THEME, types.ONBOARD_GET_THEME_SUCCESS, types.ONBOARD_GET_THEME_FAILED],
    payload: {
      api: 'gms',
      request: publicReq.publicGetThemeByUUID(uuid)
    }
  })

export const getVATNumberCheck = (ticketUUID: string | null, vatNumber: string) =>
  AsyncAction<GETPublicVATNumberCheckResponse>({
    type: [types.ONBOARD_GET_VAT_NUMBER_CHECK, types.ONBOARD_GET_VAT_NUMBER_CHECK_SUCCESS, types.ONBOARD_GET_VAT_NUMBER_CHECK_FAILED],
    payload: {
      api: 'gms',
      request: publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, VAT: vatNumber })
    }
  })

export const emailCheck = (ticketUUID: string | null, email: string) =>
  createAsyncApiAction<EmailCheckResponse>('gms', publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, PersonalEmail: email }))

export const shopPhoneNumberCheck = (ticketUUID: string | null, phoneNumber: string) =>
  createAsyncApiAction<GETPublicShopPhoneNumberCheckResponse>(
    'gms',
    publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, PhoneNumber: phoneNumber })
  )

export const shopEmailCheck = (ticketUUID: string | null, shopEmailAddress: string) =>
  createAsyncApiAction<GETPublicShopEmailCheckResponse>(
    'gms',
    publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, ShopEmail: shopEmailAddress })
  )

export const orderAccountCheck = (ticketUUID: string | null, orderAccountID: string, wholesalerUUID: string) =>
  createAsyncApiAction<GETPublicOrderAccountCheckResponse>(
    'gms',
    publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, UserID: orderAccountID, WholesalerUUID: wholesalerUUID })
  )

export const postAddressCheck = (ticketUUID: string | null, data: POSTAddressCheck) =>
  createAsyncApiAction<POSTPublicShopAddressCheckResponse>('gms', publicReq.publicPostDuplicationCheck({ TicketUUID: ticketUUID, ...data }))

export const onboardingSelfSignUp = (data: POSTOnboardingSignUp) =>
  createAsyncApiAction<GETResponse<CognitoResponse>>('ONBOARDING_SIGN_UP', 'gms', publicReq.onboardingSelfSignUp(data))

export const onboardingLinkLicenses = () => createAsyncApiAction<{}>('gms', publicReq.onboardingLinkHaynesLicenses())

export const onboardingExternalGMSList = () =>
  createAsyncApiAction<publicReq.GETPublicExternalGMSResponse>('gms', publicReq.getOnboardingExternalGMSList())

export const getOnboardingRegions = () => createAsyncApiAction<IArrayResponse<ShopRegion>>('gms', publicReq.getOnboardingRegions())

export const setAccessToken = (token: string) => ({
  type: types.SET_ACCESS_TOKEN,
  payload: {
    data: {
      Data: {
        AuthenticationResult: {
          AccessToken: token,
          IDToken: token
        }
      }
    }
  }
})

export const setOnboardingToken = (token: AuthenticationResult) => ({
  // onboarding tokens are temporary and not attached to a shop/user
  type: types.SET_ONBOARDING_TOKEN,
  payload: {
    data: {
      Data: {
        AuthenticationResult: {
          AccessToken: token.AccessToken,
          IDToken: token.IDToken
        }
      }
    }
  }
})

export const getUserEmployee = () =>
  AsyncAction<GETEmployeeResponse>({
    type: [types.GET_USER_EMPLOYEE, types.GET_USER_EMPLOYEE_SUCCESS, types.GET_USER_EMPLOYEE_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: '/modules/employees/current'
      }
    }
  })

export const getEmployeeLeaveBalance = (employeeUUID: string, year: string) =>
  AsyncAction<GETLeaveBalanceResponse>({
    type: [types.GET_LEAVE_BALANCE, types.GET_LEAVE_BALANCE_SUCCES, types.GET_LEAVE_BALANCE_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: `/modules/leave/${employeeUUID}/${year}`
      }
    }
  })

export const getEmployeeConsolidatedLeaveBalance = (employeeUUID: string, year: string) =>
  AsyncAction<GETConsolidatedLeaveBalanceResponse>({
    type: [types.GET_CONSOLIDATED_LEAVE_BALANCE, types.GET_CONSOLIDATED_LEAVE_BALANCE_SUCCES, types.GET_CONSOLIDATED_LEAVE_BALANCE_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: `/modules/consolidated-leave/${employeeUUID}/${year}`
      }
    }
  })

export const getEmployeeLeaveYearList = (employeeUUID: string) =>
  AsyncAction<GETLeaveYearListResponse>({
    type: [types.GET_LEAVE_YEAR_LIST, types.GET_LEAVE_YEAR_LIST_SUCCES, types.GET_LEAVE_YEAR_LIST_FAILED],
    payload: {
      api: 'gms',
      request: {
        method: 'GET',
        url: `/modules/employees/${employeeUUID}/leave`
      }
    }
  })

export const getLocalisationSettings = (level?: string) =>
  AsyncAction<GETLocalisationSettingsResponse>({
    type: [types.GET_LOCALISATION_SETTINGS, types.GET_LOCALISATION_SETTINGS_SUCCESS, types.GET_LOCALISATION_SETTINGS_FAILED],
    payload: {
      api: 'gms',
      request: settingsRequests.getLocalisationSettings(level)
    }
  })

export const updateLocalisationSettings = (data: PUTLocalisationSettingsBody, level?: string) =>
  AsyncAction<PUTLocalisationSettingsBody>({
    type: [types.UPDATE_LOCALISATION_SETTINGS, types.UPDATE_LOCALISATION_SETTINGS_SUCCESS, types.UPDATE_LOCALISATION_SETTINGS_FAILED],
    payload: {
      api: 'gms',
      request: settingsRequests.updateLocalisationSettings(data, level)
    }
  })

export const getGlobalLocalisationSettings = () =>
  AsyncAction<IResponse<{ Locale: Locales; Timezone: string; TimeNotation: string; DateNotation: string }>>({
    type: [
      types.GET_LOCALISATION_SETTINGS_GLOBAL,
      types.GET_LOCALISATION_SETTINGS_GLOBAL_SUCCESS,
      types.GET_LOCALISATION_SETTINGS_GLOBAL_FAILED
    ],
    payload: {
      api: 'gms',
      request: settingsRequests.getLocalisationSettings('user')
    }
  })

export const logout = () => ({
  type: types.LOGOUT
})

export const setTempLocale = (locale: Locales) => ({
  type: [types.SET_TEMP_LOCALE],
  locale
})

export const setCurrentShop = (currentShop: string) => ({
  type: types.SET_CURRENT_SHOP,
  payload: { currentShop }
})

export const clearChallenges = () => ({
  type: types.CLEAR_CHALLENGES
})

export const inviteUserToShop = (data: publicReq.POSTInviteUserToExistingShopRequest) =>
  createAsyncApiAction<publicReq.POSTInviteUserToExistingShopResponse>('gms', publicReq.publicPostInviteUserToExistingShop(data))
